$zoom-control-width: 1.75rem;
$zoom-control-height: 2.25rem;

/* stylelint-disable selector-class-pattern */
.leaflet-control-zoom {
  padding: 0 4px;
  background: var(--color-surf-container);
  border: 0 !important;
  border-radius: var(--radius-xs) !important;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.3), 0 2px 6px 2px rgba(0, 0, 0, 0.15) !important;
}

.leaflet-control-zoom-in,
.leaflet-control-zoom-out {
  width: $zoom-control-width !important;
  height: $zoom-control-height !important;
  color: var(--color-on-surf-variant) !important;
  font-weight: 400 !important;
  font-size: $zoom-control-width !important;
  background: var(--color-surf-container) !important;
}

.leaflet-control-zoom-in {
  height: calc(#{$zoom-control-height} + 1px) !important;
  border-bottom: 1px solid var(--color-outline-variant) !important;
}
